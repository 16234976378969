exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-amblyopia-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/amblyopia.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-amblyopia-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-amd-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/AMD.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-amd-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cataract-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/cataract.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cataract-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-conjunctivitis-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/conjunctivitis.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-conjunctivitis-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-diabetic-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/diabetic.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-diabetic-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-dryeyes-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/dryeyes.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-dryeyes-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-eye-checkup-kids-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/eye-checkup-kids.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-eye-checkup-kids-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-eye-checkup-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/eye-checkup.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-eye-checkup-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-gly-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/gly.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-gly-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-keratitis-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/keratitis.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-keratitis-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-lasik-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/lasik.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-lasik-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-oculoplastic-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/oculoplastic.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-oculoplastic-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-spec-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/spec.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-spec-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-vitreous-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=D:/Code/Production/Gatsby-Dristi/blog/vitreous.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-vitreous-mdx" */),
  "component---src-pages-branch-js": () => import("./../../../src/pages/branch.js" /* webpackChunkName: "component---src-pages-branch-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-testimonial-js": () => import("./../../../src/pages/testimonial.js" /* webpackChunkName: "component---src-pages-testimonial-js" */)
}

